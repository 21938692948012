import { render, staticRenderFns } from "./MineHelp.vue?vue&type=template&id=1898c478&scoped=true&"
import script from "./MineHelp.vue?vue&type=script&lang=js&"
export * from "./MineHelp.vue?vue&type=script&lang=js&"
import style0 from "./MineHelp.vue?vue&type=style&index=0&id=1898c478&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1898c478",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
