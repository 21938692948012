
import PointHead from './components/PointHead'
import PointDetail from './components/PointDetail'
import PointList from './components/PointList'

export default {
    name: 'MemberPoint',
    components: {
        PointHead,
        PointDetail,
        PointList
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            middleEmit: {},
            member: {}, // 会员信息
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // emit
        getMemberData(v) {
            this.member = v
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
        }
    },
}
