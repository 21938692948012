
export default {
    name: 'PointDetail',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            member: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getMemberListAjax()
    },
    methods: {
        // 获取会员信息
        getMemberListAjax() {
            this.$api.member.getMemberInfo().then(response => {
                const res = response.result || {};
                this.member = res
                !this.member.memberPoints && (this.member.memberPoints = [])
                this.setData()
            })
        },
        setData() {
            this.member.nextPoint = {}
            this.member.memberPoints.forEach(p => {
                p.className = 'PointDetail-step-current'
                if(p.level < this.member.userLevel) {
                    p.className = 'PointDetail-step-unlock'
                }
                if(p.level > this.member.userLevel) {
                    p.className = 'PointDetail-step-lock'
                }
                if(p.level == this.member.userLevel) {
                    this.member.currentPoint = p
                }
                if(p.level == this.member.userLevel + 1) {
                    this.member.nextPoint = p
                }
            })
            // max
            let length = this.member.memberPoints.length;
            let max = this.member.memberPoints.filter((item, i) => {
                return i == length - 1
            });
            let maxObj = max[0] || {};
            this.member.pointLength = length
            this.member.averagePercentage = 100 / (length - 1)
            this.member.maxLevel = maxObj.levelPoints
            this.middleEmit.fn('getMemberData', this.member)
        },
        // 进度
        setPercentage(n, t) {
            const nextLevelPoint = this.member?.nextPoint?.levelPoints || 0;
            const currentLevelPoint = this.member?.currentPoint?.levelPoints || 0;
            const lengthPoint = nextLevelPoint - currentLevelPoint;
            const surplusPoint = n - currentLevelPoint;
            const currentPercentage = (surplusPoint / lengthPoint) * this.member.averagePercentage;
            this.member.percentage = ((currentPercentage) + (this.member.userLevel - 1) * this.member.averagePercentage)
            if(!nextLevelPoint) this.member.percentage = 100
            return this.member.percentage || 0
        },
    },
}
