
export default {
    name: 'BirthdayTab',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            query: {},
            member: {},
            level: {
                level: '',
                list: []
            },
            coupon: {
                info: {},
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getMemberInfoAjax()
        this.getBirthdayLevelAjax()
    },
    methods: {
        // 获取会员信息
        getMemberInfoAjax() {
            this.$api.member.getMemberInfo().then(response => {
                const res = response.result || {};
                this.member = res
                this.level.level = String(this.query.level || this.member.userLevel || 1)
                this.middleEmit.fn('changeTab', this.level)
                this.getBirthdayInfoAjax()
            })
        },
        // 获取会员生日等级
        getBirthdayLevelAjax() {
            this.$api.member.getMemberBirthdayLevel().then(response => {
                const res = response.result || {};
                this.level.list = res || []
            })
        },
        // 获取会员生日优惠券
        getBirthdayInfoAjax() {
            this.$api.member.getMemberBirthdayCoupon({
                level: this.level.level
            }).then(response => {
                const res = response.result || {};
                // state -> 1 未设置生日 2 已领取未使用 3 已领取已使用 4 未领取 5 未满足 6 不存在生日礼物权益
                this.coupon.info = res
                this.coupon.list = [res]
                this.coupon.visible = !!res.percent
                this.coupon.list.forEach(p => {
                    p.className = p.state == 3 ? 'BirthdayTab-coupon--disabled' : ''
                })
            })
        },
        // change
        changeTab() {
            this.getBirthdayInfoAjax()
            this.middleEmit.fn('changeTab', this.level)
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query || {}
        }
    },
}
