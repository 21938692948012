
export default {
    name: 'BirthdayList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        level: Object
    },
    data() {
        return {
            point: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {
        level: {
            handler() {
                this.setLevel()
            },
            deep: true,
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 获取权益商品列表
        getBirthdayInfoAjax() {
            this.$api.member.getMemberBirthdayProduct({
                level: this.level.level,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                this.point.list = [...this.point.list, ...(res.list || [])]
                this.point.visible = !!this.point.list.length
                this.setData()
            })
        },
        setData() {
            this.point.list.forEach(item => {
                // 标签
                let reProductLabel = []
                !item.productLabel && (item.productLabel = [])
                item.productLabel.forEach(list => {
                    reProductLabel.push({
                        ...list,
                        style: `color: ${list.color};background: ${list.backgroundColor};`
                    })
                })
                if(item.discountRate) {
                    reProductLabel.push({
                        name: `-${item.discountRate}%`,
                        style: `color:#fff;background: #F8981C;`
                    })
                }
                item.reProductLabel = reProductLabel[0] && [reProductLabel[0]] || []
            })
        },
        setLevel() {
            this.point.list = []
            this.getBirthdayInfoAjax()
        },
        // 触底加载
        emitBottomLoading(e, callBack) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getBirthdayInfoAjax()
            }
            callBack(!hasNext)
        }
    },
}
