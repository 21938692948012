
export default {
    name: 'MineTip',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            hasCoupon: false,
            coupon: {
                length: 0,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.setTip()
    },
    methods: {
        getExpireCouponAjax() {
            this.$api.coupon.getExpireCoupon().then(response => {
                const res = response.result || [];
                this.coupon.list = res;
                this.coupon.length = this.coupon.list.length
                this.hasCoupon = !!this.coupon.length
            })
        },
        setTip() {
            const expireTime = this.$storage.get('coupon/couponTipTime');
            const nowTime = this.$dayjs().valueOf();
            // const hasCoupon = this.hasLogin && (!expireTime || nowTime > expireTime)
            const hasCoupon = this.hasLogin
            hasCoupon && this.getExpireCouponAjax()
        },
        handleClose() {
            const nowTime = this.$dayjs().valueOf();
            const expireTime = nowTime + (24 * 60 * 60 * 1000);
            this.hasCoupon = false
            this.$storage.set('coupon/couponTipTime', expireTime)
        },
        handleTip() {
            this.handleClose()
            this.$router.push({
                name: 'Coupon'
            })
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
        }
    },
}
