
export default {
    name: 'MineOrder',
    props: {},
    data() {
        return {
            hasLogin: false,
            list: []
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        if(this.hasLogin) {
            this.getCountOrderAjax()
        }
    },
    methods: {
        // 用户个人中心订单统计接口
        getCountOrderAjax() {
            this.$api.user.getCountOrder().then(response => {
                const res = response.result || {};
                this.list[0].num = res.unshipped
                this.list[1].num = res.shipped
                this.list[2].num = res.unComment
            })
        },
        jumpOrder(obj) {
            const hasLogin = this.$login();
            if(!hasLogin) {
                this.$router.push({
                    name: 'Login',
                })
                return
            }
            const query = obj ? {
                type: obj.type || ''
            } : {};
            if(!obj.type) {
                this.$router.push({
                    name: 'OrderList',
                    query
                })
                return
            }
            if(obj.type == 'WaitComment') {
                this.$router.push({
                    name: 'Review-Personal'
                })
                return
            }
            this.$router.push({
                name: 'OrderList',
                query
            })
        },
        initialSet() {
            this.hasLogin = this.$login()
            this.list = [{
                icon: 'icon-deliver',
                name: this.$translate('Processing'),
                num: 0,
                type: 'Unshipped'
            }, {
                icon: 'icon-track-right',
                name: this.$translate('Shipped'),
                num: 0,
                type: 'Shipped'
            }, {
                icon: 'icon-sms',
                name: this.$translate('Reviews'),
                num: 0,
                type: 'WaitComment'
            }, {
                icon: 'icon-return',
                name: this.$translate('Returns'),
                num: 0,
                type: 'Close'
            }]
        }
    },
}
