
import BirthdayUser from './components/BirthdayUser'
import BirthdayTab from './components/BirthdayTab'
import BirthdayList from './components/BirthdayList'

export default {
    name: 'MemberBirthday',
    components: {
        BirthdayUser,
        BirthdayTab,
        BirthdayList
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            middleEmit: {},
            level: {}, // 权益等级
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // emit
        changeTab(v) {
            this.level = v
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
        }
    },
}
