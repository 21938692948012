
export default {
    name: 'PointHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        member: Object
    },
    data() {
        return {
            popupCompo: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleTip() {
            this.popupCompo.visible = true
        }
    },
}
