
export default {
    name: 'MemberEquity',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        member: Object,
        level: Object
    },
    data() {
        return {
            icon: {},
            currency: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        handleMore(obj) {
            this.$router.push({
                name: 'MemberBirthday',
                query: {
                    level: this.level.level
                }
            })
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.icon = {
                1: 'https://cdn2.selleroa.com/yfn-upload/review/1694412947837.png',
                2: 'https://cdn2.selleroa.com/yfn-upload/review/1694412951444.png',
                3: 'https://cdn2.selleroa.com/yfn-upload/review/1694412953939.png',
                4: 'https://cdn2.selleroa.com/yfn-upload/review/1694412956544.png',
                5: 'https://cdn2.selleroa.com/yfn-upload/review/1694412958790.png',
                6: 'https://cdn2.selleroa.com/yfn-upload/review/1694412961029.png',
            }
        }
    },
}
