
export default {
    name: 'MineRec',
    data() {
        return {
            listCompo: {
                list: [],
                options: {
                    hasMore: true,
                    hasConcat: true,
                }
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecAjax()
    },
    methods: {
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 4,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
            },this.page.pageNum==1).then(response => {
                const res = response?.result || {};
                this.listCompo.list = res.list || []
                this.page.totalPage = res.totalPage
            })
        },
        emitAddBag() {
            this.middleEmit.fn('getProductParams')
            this.middleEmit.fn('getBagAjax')
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getRecAjax()
            }
            callBack(!hasNext)
        }
    },
}
