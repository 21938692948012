
export default {
    name: 'MemberHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        member: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: this.member.contextId
                }
            })
        }
    },
}
