
import MemberLevel from './components/MemberLevel'
import MemberEquity from './components/MemberEquity'

export default {
    name: 'MemberCenter',
    components: {
        MemberLevel,
        MemberEquity
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            middleEmit: {},
            member: {},         // 会员
            level: {            // 等级
                memberRights: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // emit
        changeMember(v) {
            this.member = v
        },
        changeLevel(v) {
            this.level = v
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
        }
    },
}
