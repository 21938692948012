
export default {
    name: 'MineHelp',
    data() {
        return {
            hasLogin: false,
            list: []
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        handleList(obj) {
            if(obj.login && !this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
            } else {
                this.$router.push({
                    name: obj.url,
                    query: obj.query
                })
            }
        },
        initialSet() {
            this.hasLogin = this.$login()
            this.list = [{
                list: [{
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697174238142.png',
                    name: this.$translate('Recently Viewed'),
                    url: 'ProductRecently'
                }, {
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697174997462.png',
                    name: this.$translate('Address'),
                    url: 'AddressList',
                    login: true
                }, {
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697439491256.png',
                    name: this.$translate('Help Center'),
                    url: 'ContactUs'
                }, {
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697162822321.png',
                    name: this.$translate('Feedback'),
                    url: 'Feedback',
                    login: true
                }]
            }, {
                list: [{
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697439555587.png',
                    name: this.$translate('Influencer Program'),
                    url: 'Influencer-Index'
                }, {
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1703836154617.png',
                    name: this.$translate('YFN Exchange'),
                    url: 'Exchange',
                }, {
                    icon: 'https://cdn2.selleroa.com/yfn-upload/review/1697175055158.png',
                    name: this.$translate('About YFN'),
                    url: 'AboutUs',
                }]
            }]
        }
    },
}
