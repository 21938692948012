
export default {
    name: 'PointList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            point: {},
            list: [],
            page: {
                totalPage: 0,
                pageSize: 20,
                pageNum: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getPointListAjax()
    },
    methods: {
        // 获取会员信息
        getPointListAjax() {
            this.$api.member.getMemberPoint({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum
            }).then(response => {
                const res = response.result || {};
                this.point = res
                !this.point.list && (this.point.list = [])
                this.page.totalPage = res.totalPage
                this.list = [...this.list, ...this.point.list]
                this.point.visible = !!this.list.length
            })
        },
        loadMore(v) {
            this.$fnApi.debounce(() => {
                const e = v && (v[0] || {}) || {};
                if(e.isIntersecting && this.page.totalPage > this.page.pageNum) {
                    this.page.pageNum += 1
                    this.getPointListAjax()
                }
            }, 100)
        }
    },
}
