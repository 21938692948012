
import MemberHead from './MemberHead'

export default {
    name: 'MemberLevel',
    components: {
        MemberHead
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            member: {
                visible: false
            },
            level: {
                unLock: true
            },
            bg: {},
            iconRight: {},
            color: {},
            params: {
                currentIndex: 1
            },
            swiperCompo: {}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getMemberListAjax()
    },
    methods: {
        // 获取会员信息
        getMemberListAjax() {
            this.$api.member.getMemberInfo().then(response => {
                const res = response.result || {};
                this.member = res
                !this.member.memberPoints && (this.member.memberPoints = [])
                this.setMember()
                this.setSwiper()
                this.getMemberLevelAjax()
                this.member.visible = !!this.member.memberPoints.length
                this.middleEmit.fn('changeMember', this.member)
            })
        },
        setMember() {
            let length = this.member.memberPoints.length;
            let lastObj = this.member.memberPoints[length - 1] || {};
            // 等级list
            let hasMaxLevel = this.member.countPoints >= lastObj.levelPoints;
            if(hasMaxLevel) this.member.nextSubPoints = lastObj.levelPoints
            this.member.memberPoints.forEach((p, i) => {
                p.hasUnlock = p.levelPoints <= this.member.countPoints
                p.hasLock = p.levelPoints > this.member.countPoints
                p.hasCurrent = p.level == this.member.userLevel && this.member.nextSubPoints
                p.nextSubPoints = hasMaxLevel ? this.member.nextSubPoints  : this.member.nextSubPoints + this.member.countPoints
                if(p.hasLock || !this.member.nextSubPoints) {
                    p.tips = `${this.$translate('points can be reach')}`
                }
                if(p.hasUnlock) {
                    p.tips = this.$translate('You have exceeded this level')
                }
                if(p.hasCurrent) {
                    p.hasUnlock = false
                    p.tips = `${this.$translate('Get')} ${this.member.nextSubPoints} ${this.$translate('to reach')} V${p.level + 1}`
                    if(hasMaxLevel) {
                        p.tips = `${this.$translate('You are already at the highest level')}`
                    }
                }
            })
        },
        // 获取对应等级信息
        getMemberLevelAjax() {
            this.$api.member.getMemberLevelList({
                level: this.params.currentIndex
            }).then(response => {
                const res = response.result || {};
                // rightsType 1-关怀 2-会员日 3-优惠券 4-钱包 5-vip服务	6-生日
                this.level = res || {}
                !this.level.memberRights && (this.level.memberRights = [])
                this.setLevel()
                this.middleEmit.fn('changeLevel', this.level)
            })
        },
        setLevel() {
            let shippingText = ['Valid for the current month', 'Shipping Only For: FREE STANDARD SHIPPING', 'Shipping Only For: FREE EXPRESS SHIPPING'];
            this.level.memberRights.forEach(p => {
                if([2, 3].includes(p.rightsType)) {
                    p.shippingText = this.$translate(shippingText[p.couponRights.freeShippingType])
                }
            })
        },
        // 进度
        setPercentage(n, t) {
            let percentage = t ? Math.ceil((n / t) * 100) : 0;
            percentage > 100 && (percentage = 100)
            return percentage
        },
        // swiper
        setSwiper() {
            this.swiperCompo = {
                // loop: true,
                speed: 900,
                centeredSlides: true,
                slidesPerView: 2,
                spaceBetween: '-55%',
                effect : 'coverflow',
                grabCursor: true,
                initialSlide: this.member.userLevel - 1,
                coverflowEffect: {
                    rotate: 0,
                    stretch: '-280%',
                    depth: 360,
                    modifier: 1,
                    slideShadows : false
                },
                on: {
                    slideChange: (e) => {
                        const ref = this.$refs['MemberLevel-swiper']?.$refs['MemberLevel-swiper']?.swiper;
                        this.params.currentIndex = ref.activeIndex + 1
                        this.setCurrent()
                    }
                }
            }
        },
        setCurrent() {
            this.$fnApi.debounce(() => {
                this.getMemberLevelAjax()
            }, 500)
        },
        handleLevel() {
            this.$router.push({
                name: 'MemberPoint'
            })
        },
        // 初始化
        initialSet() {
            this.bg = {
                1: 'https://cdn2.selleroa.com/yfn-upload/review/1694400783821.png',
                2: 'https://cdn2.selleroa.com/yfn-upload/review/1694400790513.png',
                3: 'https://cdn2.selleroa.com/yfn-upload/review/1694400792971.png',
                4: 'https://cdn2.selleroa.com/yfn-upload/review/1694400795647.png',
            }
            this.iconRight = {
                1: 'https://cdn2.selleroa.com/yfn-upload/review/1694409486763.png',
                2: 'https://cdn2.selleroa.com/yfn-upload/review/1694409492003.png',
                3: 'https://cdn2.selleroa.com/yfn-upload/review/1694409494558.png',
                4: 'https://cdn2.selleroa.com/yfn-upload/review/1694409497480.png',
            }
            this.color = {
                1: 'linear-gradient(to right, #7FA1D4, #29447B)',
                2: 'linear-gradient(to right, #DFA936, #9C5300)',
                3: 'linear-gradient(to right, #F39789, #DC4F39)',
                4: 'linear-gradient(to right, #AE9FD3, #53447E)',
            }
        }
    },
}
